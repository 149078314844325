import * as React from 'react';
import styled from 'styled-components';
// import {useStaticQuery, graphql} from 'gatsby';
// import {GatsbyImage, getImage} from 'gatsby-plugin-image';
// import Gallery from '@browniebroke/gatsby-image-gallery';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';
import Page from '../components/Page';
import Box from '../components/Box';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';

// styles
const TitleWrapper = styled(Box).attrs({
  mt: '35px',
})``;

const SubTitle = styled(Box).attrs({
  mt: '40px',
  mb: '25px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;
const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
`;

const Anchor = styled.a`
  color: #6d7770;
  margin-top: 5px;
  word-wrap: break-word;
  :hover {
    color: black;
  }
`;

// const useGallery = () => {
//   const data = useStaticQuery(graphql`
//     query GalleryImages {
//       images: allS3Object(filter: {Key: {glob: "content/gallery/*"}}) {
//         nodes {
//           Key
//           localFile {
//             childImageSharp {
//               thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
//               full: gatsbyImageData(layout: FULL_WIDTH)
//             }
//           }
//         }
//       }
//     }
//   `);
//   return data.images.nodes.map(node => node.localFile.childImageSharp);
// };

const GettingAroundPage = () => {
  return (
    <Page>
      <ContentWrapper>
        <TitleWrapper>
          <Title text={'Getting Around Beaver Creek'} />
        </TitleWrapper>
        <Box m={'0 auto'} maxWidth={'1000px'}>
          <SubTitle> Free Shuttles!!</SubTitle>
          <Content>
            Beaver Creek offers complimentary door-to-door shuttle service within the resort. Call Village
            Transportation at 970.949.1938 or your concierge to book a shuttle. The shuttles run from 6:30 a.m. to
            Midnight, 7 days a week. Please allow 15 to 20 minutes for pickup. This service is called Dial-a-Ride within
            Beaver Creek Resort only. This is the same as the{' '}
            <Anchor href={'https://apps.apple.com/us/app/village-connect/id1357326540'}>“Village Connect App”</Anchor>{' '}
            App.
          </Content>
          <Content>Please Note that this service is NOT available to and from the Westin.</Content>

          <SubTitle>Taxi Services</SubTitle>
          <Content>
            Both of the cab companies listed below offer 24 hour door to door service and have 6 passenger caravans.
            <br />
            High Mountain Taxi – 970.524.5555
            <br />
            Vail Valley Taxi – 970.476.8294
          </Content>
          <Content>
            Uber & Lyft both service the Beaver Creek & Bachelor Gulch area. Supply can be limited and wait times long,
            so plan in advance if using them.
          </Content>

          <SubTitle>Transportation To/From Avon</SubTitle>
          <Content>
            The Town of Avon offers free buses that run to Beaver Creek Village from 8:00 am – 5:30 pm during the winter
            ski season. These travel between the Covered Bridge Transportation Center and Avon Station near the Westin.
            Riverfront Resort & Spa.
          </Content>
          <Content>
            Avon Transit also runs an Avon Restaurant Shuttle until April 1 from the Beaver Creek Covered Bridge
            Transportation Center. This shuttle runs from 6:00 - 10:00 pm and makes stops near most of Avon’s
            restaurants including Maya at the Westin, Vin 48, Blue Plate, Loaded Joe’s, Pazzo’s, and Nozawa.
          </Content>
          <Content>
            The bus schedule can be found <Anchor href="https://www.avon.org/2038/Free-Spaces-to-Explore">HERE</Anchor>
          </Content>
        </Box>
      </ContentWrapper>
    </Page>
  );
};

export default GettingAroundPage;
